<template>
  <div class="home-page">
    <div class="page-header clear-filter" filter-color="primary">
        <div class="page-header-image"></div>
        <div class="content-center">
            <div class="row">
              <div class="col-md-10 ml-auto mr-auto">
                <h1 class="title">Ready to make your first booking?</h1>
                <h4>Access to doctors and wellness professionals wherever you are!</h4>

                <button class="btn-lg btn-primary" outline="" @click="signup">BOOK NOW</button>
              </div>
            </div>
        </div>
    </div>
    <div class="main">
      <div class="section section-basic-components pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-7 ml-auto mr-auto">
              <h2 class="title">Why DrRemo?</h2>
              <h5 class="description">
                    How often have you fell ill or injured yourself but could not secure a doctor's appointment because you found yourself outside of the nine to five window? 
                    If that was not enough headache already, searching online for doctors near you is sure to give you one. 
                    DrRemo breaks down these barriers by giving you an online booking system to book doctor's appointments any time of day or night 24/7. 
                    You can search for doctors near you in one convenient platform. A one stop shop to find GP's, chiropractors, physiotherapists, psychologist and many more.
              </h5>
            </div>
            <div class="col-lg-6 col-md-12">
              <img class="img" alt="Doctor Portrait" :src="this.cdnEndpoint + '/img/home/doctor-portrait.jpg'" />
            </div>
          </div>
        </div>
      </div>

      <div class="section section-content" data-background-color="black" >
        <div  class="container">
          <div  class="row">
            <div  class="col-md-5">
              <img class="img img-raised rounded image-2" alt="Family Wellness" :src="this.cdnEndpoint + '/img/home/home_family_wellness.jpg'" />
            </div>
            <div  class="col-md-4 ml-auto mr-auto">
              <div  class="section-description">
                <h2  class="title">Wellness, for every member of your family.</h2>
                <h5  class="description">
                  DrRemo connects wellness professionals and patients, bringing essential services to your fingertips. 
                  No more online searches, time consuming phone calls and lengthy diary comparisons. 
                  Our remote consultation options also allow you to receive the care you need in the comfort of your own home. 
                  No need to brace traffic or rush to appointments anymore. 

                  Choose from a variety of wellness professionals for you, your family and for your babies.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-content">
        <div  class="container practitioner-container">
          <div  class="row">
            <div  class="col-md-12 ml-auto mr-auto">
              <div  class="section-description">
                <h2 class="title d-flex justify-content-center">Are you a practitioner?</h2>
                <h5 class="description d-flex justify-content-center">We know running a practice is hard work, let us help you with a few things.</h5>
                <div class="row">                  
                  <div class="col-md-12 d-flex flex-wrap justify-content-center">
                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="far fa-calendar-alt p-2"/></div>
                      
                      <h5 class="card-title"><b>Booking system</b></h5>
                      <div class="card-text description">Receive and manage appointments in near real time through our booking system.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-video p-2"/></div>
                      
                      <h5 class="card-title"><b>Video, call and chat functionality</b></h5>
                      <div class="card-text description">Facilitate virtual consultations through the DrRemo desktop or mobile application clients.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-envelope p-2"/></div>
                      
                      <h5 class="card-title"><b>Tailored communication</b></h5>
                      <div class="card-text description">Send communication to patients with a customized template for your practice.</div>
                    </card>
                  </div>

                  <div class="col-md-12 d-flex flex-wrap justify-content-center">
                    <card class="col-md-3 text-center m-4" >
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-credit-card p-2"/></div>
                      
                      <h5 class="card-title"><b>Weekly payments</b></h5>
                      <div class="card-text description">Receive payments each week as bookings are made on the platform.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="far fa-window-close p-2"/></div>
                      
                      <h5 class="card-title"><b>Cancellation management</b></h5>
                      <div class="card-text description">Our cancellation policy minimize financial losses and allows for timeous rescheduling.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-chart-line p-2"/></div>
                      
                      <h5 class="card-title"><b>Reporting</b></h5>
                      <div class="card-text description">Gain insights and understanding into the daily operations of your practice.</div>
                    </card>
                  </div>

                  <div class="col-md-12 d-flex flex-wrap justify-content-center">
                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-search p-2"/></div>
                      
                      <h5 class="card-title"><b>Visibility and exposure</b></h5>
                      <div class="card-text description">Increased marketability and traffic to your practice by connecting you to patients through DrRemo.</div>
                    </card>
                  </div>
                </div>

                <div class="col-md-12 d-flex justify-content-center">
                  <button class="btn-lg btn-primary" outline="" @click="signup">REGISTER NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-content" data-background-color="black" >
        <div  class="container patient-container">
          <div  class="row">
            <div  class="col-md-12 ml-auto mr-auto">
              <div  class="section-description">
                <h2 class="title d-flex justify-content-center">Are you a patient?</h2>
                <h5 class="description d-flex justify-content-center">Not everyone has access to wellness practitioners around the clock, but everyone should.</h5>
                <div class="row">
                  <div class="col-md-12 d-flex flex-wrap justify-content-center">
                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-stopwatch p-2"/></div>
                      
                      <h4 class="card-title"><b>Accessibility</b></h4>
                      <div class="card-text description">Quick access to a variety of wellness professionals.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-calendar p-2"/></div>
                      
                      <h4 class="card-title"><b>Online Bookings</b></h4>
                      <div class="card-text description">Avoid long phone calls and diary matching by using our online booking functionality.</div>
                    </card>

                    <card class="col-md-3 text-center m-4">
                      <div slot="avatar" class="d-flex justify-content-center"><i class="fa fa-video p-2"/></div>
                      
                      <h4 class="card-title"><b>Virtual consultations</b></h4>
                      <div class="card-text description">Access to wellness practitioners from the comfort of your own home.</div>
                    </card>
                  </div>
                  
                  <div class="col-md-12 d-flex justify-content-center">
                    <button class="btn-lg btn-primary" outline="" @click="signup">BOOK NOW</button>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "@/components";
import { Card } from '@/components'

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Card,
    [Button.name]: Button,
  },
  methods: {
    signup() {
        window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;
    }
  },
  metaInfo() {
    return {
      title: "DrRemo | Wellness, Wherever you are - Home",
      meta: [
        {
          name: "description",
          content: "Maximizing patient experience while providing practitioners with exposure, marketability and back office functionality."
        },
        {
          name: "robots",
          content: "index, follow"
        }
      ],
      link: [
        {
          rel: "canonical",
          href: "https://dr-remo.co.za/"
        }
      ]
    }
  }
};
</script>
<style></style>
